const smtPqShared = {
  body: {
    bgColor: {
      default: 'rgb(13, 14, 17)',
    }
  },
  section: {
    bgColor: {
      default: '#F6F7FA',
      _dark: 'gray.900'
    },
    color: {
      default: 'gray.900',
      _dark: 'white'
    },
    label: {
      color: {

      }
    }
  }
};

export default smtPqShared;
