module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Jaen App","short_name":"Jaen","start_url":"/","background_color":"#f7f0eb","theme_color":"#a2466c","display":"standalone","icon":"src/favicon.ico","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1033137f79805055bf25fd77a59b85a7"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-M58K75M9PG"],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"sampleRate":1,"enableTracing":true,"debug":true,"tracesSampleRate":1,"replaysSessionSampleRate":1,"replaysOnErrorSampleRate":1,"dsn":"https://0500df0c84c0af9c06527751e6f640b8@sentry.cronit.io/12"},
    },{
      plugin: require('../node_modules/gatsby-plugin-jaen/gatsby-browser.tsx'),
      options: {"plugins":[],"pylonUrl":"https://pylons.photonq.org/jaen-agent/graphql","remote":{"repository":"atsnek/photonq"},"zitadel":{"organizationId":"268207341512496739","clientId":"268418317486724723@photonq","authority":"https://accounts.photonq.org","redirectUri":"https://dev.photonq.org","projectIds":["263491274097563233"]},"sentry":{"org":"cronit","project":"photonq","dsn":"https://0500df0c84c0af9c06527751e6f640b8@sentry.cronit.io/12"},"googleAnalytics":{"trackingIds":["G-M58K75M9PG"]}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
