const smtFooter = {
  bgColor: {
    default: 'gray.100',
    _dark: '#1B222E'
  },
  textColor: {
    default: 'gray.800',
    _dark: 'gray.400'
  }
};

export default smtFooter;
