const smtHeadingComponent = {
  link: {
    color: {
      default: {
        default: 'gray.200',
        _dark: 'gray.700'
      },
      active: {
        default: 'gray.500',
        _dark: 'gray.400'
      }
    }
  }
};

export default smtHeadingComponent;
