const smtPqFeatureCard = {
  color: {
    default: '#747781',
    _dark: 'gray.400'
  },
  title: {
    color: {
      default: 'brand.500',
      _dark: 'brand.600'
    }
  }
};

export default smtPqFeatureCard;
