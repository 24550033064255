const smtPqSectionLabel = {
  bgColor: {
    default: '#E8E9EB',
    _dark: 'gray.700'
  },
  color: {
    default: '#565656',
  }
};

export default smtPqSectionLabel;
