const smtLinkComponent = {
    a: 'b',
    _hover: {
        color: {
            default: 'brand.500',
            _dark: 'brand.300'
        },
    },
};

export default smtLinkComponent